<template>
    <div :class="sizeStyle.container" class="overflow-y-clip h-auto origin-bottom flex items-center gap-[6px] mx-auto">
        <template v-for="(level, idx) in levelHistorySlice" :key="idx">
            <div :style="levelHeight(level)" :class="sizeStyle.bar" class="w-[3px] transition-all duration-200 ease-linear rounded-full bg-[#555BA2]"></div>
        </template>
    </div>
</template>

<script>
// How the initial bars are shown
export const INITIAL_DISPLAY = {
    // All bars are equal height
    EQUAL: "equal",
    // Bars are oscillating
    OSCILLATING: "oscillating",
};

export const BAR_SIZE = {
    NORMAL: "normal",
    SMALL: "small",
    TINY: "tiny",
};
</script>
<script setup>
import { useMediaQuery } from "@vueuse/core";
import { computed } from "vue";

const isLgBreakpoint = useMediaQuery("(min-width: 1024px)");
const isMdBreakpoint = useMediaQuery("(min-width: 768px)");

const props = defineProps({
    levels: {
        type: Array,
        required: true,
        default: () => [],
    },
    levelsToDisplay: {
        type: Number,
        default: 24,
    },
    // How the initial bars are shown
    initialDisplay: {
        type: String,
        default: INITIAL_DISPLAY.EQUAL,
    },
    barSize: {
        type: String,
        default: BAR_SIZE.NORMAL,
    },
});

/*
 * Scale down the levels count depending on the viewport size.
 * For now, mobile will display 35% the amount shown on desktop.
 * This will automatically resize when the md breakpoint is crossed.
 */
const levelsScaleFactor = computed(() => {
    if (isLgBreakpoint.value) {
        return 1;
    }
    if (isMdBreakpoint.value) {
        return 0.45;
    }
    return 0.35;
});
const levelsCount = computed(() => Math.ceil(props.levelsToDisplay * levelsScaleFactor.value));

const initialHistory = initializeLevelsHistory();

const levelHistorySlice = computed(() => {
    if (props.levels.length === 0) {
        return initialHistory.slice(-levelsCount.value);
    }

    const modifiedSlice = props.levels.map((level) => {
        return normalizeLevel(level);
    });

    return initialHistory.concat(modifiedSlice).slice(-levelsCount.value);
});

const sizeStyle = computed(() => {
    switch (props.barSize) {
        case BAR_SIZE.TINY:
            return { container: "min-h-2", bar: "h-2" };

        case BAR_SIZE.SMALL:
            return { container: "min-h-4", bar: "h-4" };

        case BAR_SIZE.NORMAL:
        default:
            return { container: "min-h-8", bar: "h-8" };
    }
});

function initializeLevelsHistory() {
    switch (props.initialDisplay) {
        case INITIAL_DISPLAY.OSCILLATING:
            const domain = [0.5, 0.75, 1];
            let upwards = true;
            let position = 0;

            return Array(levelsCount.value)
                .fill(0)
                .map(() => {
                    const result = domain[position];
                    if (upwards) {
                        position++;
                        if (position >= domain.length - 1) {
                            upwards = false;
                        }
                    } else {
                        position--;
                        if (position <= 0) {
                            upwards = true;
                        }
                    }
                    return result;
                });

        case INITIAL_DISPLAY.EQUAL:
        default:
            return Array(levelsCount.value).fill(0.5);
    }
}

function normalizeLevel(level) {
    // Add some gain
    const modified = level * 500;

    if (modified >= 100) {
        return 1;
    } else if (modified < 100 && modified >= 75) {
        return 0.75;
    } else if (modified < 75 && modified >= 50) {
        return 0.5;
    }

    return 0.25;
}

function levelHeight(level) {
    switch (level) {
        case 1:
            return "transform: scaleY(2)";
        case 0.75:
            return "transform: scaleY(1.5)";
        default:
        case 0.5:
            return "transform: scaleY(1)";
    }
}
</script>
